
import React from "react";
import CardItem from "../CardItem";
import "../Cards.css";

const slideImages = [
    {
      url: 'images/website_edu.png',
      caption: 'Thiết kế và phát triển Website trong lĩnh vực giáo dục'
    },
    {
      url: 'images/website_ecommerce.png',
      caption: 'Thiết kế và phát triển Website trong lĩnh vực kinh doanh và thương mại'
    },
    {
      url: 'images/website_beauty.png',
      caption: 'Thiết kế và phát triển Website trong lĩnh vực Spa, chăm sóc sắc đẹp'
    },
  ]; 

function Websites() {
    return (
        <>
          <div className="cards">
            <h1>Dịch vụ thiết kế và phát triển Website</h1>
            <div className="cards__container">
              <div className="cards__wrapper">
                <ul className="cards__items">
                <CardItem
                    src="images/website_edu.png"
                    text='Thiết kế và phát triển Website trong lĩnh vực giáo dục'
                    label="Website"
                    path="/services"
                  />
                  <CardItem
                    src='images/website_ecomerce.png'
                    text='Thiết kế và phát triển Website trong lĩnh vực kinh doanh và thương mại'
                    label="Website"
                    path="/services"
                  />
                  <CardItem
                    src='images/website_beauty.png'
                    text='Thiết kế và phát triển Website trong lĩnh vực Spa, chăm sóc sắc đẹp'
                    label="Website"
                    path="/services"
                  />
                </ul>
              </div>
            </div>
          </div>
        </>
      );
}

export default Websites;
