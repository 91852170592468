import React from "react";
import CardItem from "../CardItem";
import { useMediaQuery } from "react-responsive";
import ContactRow from "./ContactRow";
import Contact from "./Contact";
import PhoneNumber from "./PhoneNumber";
import PhoneNumberRow from "./PhoneNumberRow";

function About() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  if (isDesktopOrLaptop || isBigScreen)
    return (
      <>
        <div className="cards-about">
          <div class="container">
            <div class="row">
              <div className="cards-image">
                <img src="../images/img-human.jpg" className="img-fluid-row" />
              </div>
              <div>
                <h2 className="main-title">XIN CHÀO</h2>
                <p className="main-p">
                  <p>
                    Lời đầu tiên xin gửi tới tất cả Quý khách hàng lời chúc sức
                    khỏe và thành công !
                  </p>
                  <br></br>
                  <p>
                    Tôi là Phạm Văn Báo, Giám đốc kỹ thuật của Công ty TNHH Giải
                    Pháp Công Nghệ Và Đào Tạo AmazinPro.
                  </p>
                  <p>
                    Công ty chúng tôi với nhiều năm kinh nghiệm trong lĩnh vực
                    sản xuất phần mềm bao gồm thiết kế và phát triển các{" "}
                    <b>
                      <font color="red">Website</font>
                    </b>
                    , các
                    <b>
                      <font color="red"> Ứng dụng di động</font>
                    </b>{" "}
                    và các{" "}
                    <b>
                      <font color="red">Tools</font>
                    </b>{" "}
                    , giúp tăng năng suất và hiệu quả kinh tế cũng như đẩy mạnh
                    marketing cho các cá nhân, tổ chức và các công ty.
                  </p>
                  <br></br>
                  <p>
                    Chúng tôi hoạt động với vai trò là{" "}
                    <b>
                      <font color="red">đối tác kỹ thuật</font>
                    </b>{" "}
                    của Quý khách hàng :
                  </p>
                  <p>
                    + Luôn đồng hành với khách hàng trong quá trình phát triển
                    và hoạt động của sản phẩm
                  </p>
                  <p>
                    + Hỗ trợ phát triển, nâng cấp sản phẩm với chi phí hợp lý
                  </p>
                  <br></br>
                  <p>
                    Nếu bạn cần một{" "}
                    <b>
                      <font color="red">đối tác kỹ thuật</font>
                    </b>{" "}
                    cho sản phẩm của bạn, hãy chọn chúng tôi !{" "}
                  </p>
                </p>
                <div class="row">
                  <ContactRow />
                  <span>&nbsp;&nbsp;</span>
                  <PhoneNumberRow />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <div className="cards-about">
        <div class="container">
          <div class="col">
            <div class="col-sm-6">
              <img src="../images/img-human.jpg" className="img-fluid-col" />
            </div>
            <div class="main-p">
              <h2 className="main-title">XIN CHÀO</h2>
              <p className="main-p">
                <p>
                  Lời đầu tiên xin gửi tới tất cả Quý khách hàng lời chúc sức
                  khỏe và thành công !
                </p>
                <br></br>
                <p>
                  Tôi là Phạm Văn Báo, Giám đốc kỹ thuật của Công ty TNHH Giải
                  Pháp Công Nghệ Và Đào Tạo AmazinPro.
                </p>
                <p>
                  Công ty chúng tôi với nhiều năm kinh nghiệm trong lĩnh vực sản
                  xuất phần mềm bao gồm thiết kế và phát triển các{" "}
                  <b>
                    <font color="red">Website</font>
                  </b>
                  , các
                  <b>
                    <font color="red"> Ứng dụng di động</font>
                  </b>{" "}
                  và các{" "}
                  <b>
                    <font color="red">Tools</font>
                  </b>{" "}
                  , giúp tăng năng suất và hiệu quả kinh tế cũng như đẩy mạnh
                  marketing cho các cá nhân, tổ chức và các công ty.
                </p>
                <br></br>
                <p>
                  Chúng tôi hoạt động với vai trò là{" "}
                  <b>
                    <font color="red">đối tác kỹ thuật</font>
                  </b>{" "}
                  của Quý khách hàng :
                </p>
                <p>
                  + Luôn đồng hành với khách hàng trong quá trình phát triển và
                  hoạt động của sản phẩm
                </p>
                <p>+ Hỗ trợ phát triển, nâng cấp sản phẩm với chi phí hợp lý</p>
                <br></br>
                <p>
                  Nếu bạn cần một{" "}
                  <b>
                    <font color="red">đối tác kỹ thuật</font>
                  </b>{" "}
                  cho sản phẩm của bạn, hãy chọn chúng tôi !{" "}
                </p>
              </p>
              <div class="row">
                  <ContactRow />
                  <span>&nbsp;&nbsp;</span>
                  <PhoneNumberRow />
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
