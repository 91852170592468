import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
    return (
        <>
            < div className='cards' >
                <h1>Bạn có muốn biết chúng tôi có thể làm gì cho bạn ?</h1>
                <div className="cards__container">
                    <div className="cards__wrapper">
                        <ul className='cards__items'>
                            <CardItem
                                src='images/img-9.jpg'
                                text='Thiết kế và phát triển các website & tools đẹp, mượt mà và hiệu quả, thoả mãn các yêu cầu của quý khách hàng.'
                                label='Websites, Tools'
                                path='/services'
                            />
                            <CardItem
                                src='images/img-2.png'
                                text='Xây dựng và phát triển các ứng dụng di động - Mobile Application đẹp mắt và mượt mà, thời gian và chi phí triển khai rất hợp lý.'
                                label='Mobile Application'
                                path='/services'
                            />
                        </ul>
                        <ul className='cards__items'>
                            <CardItem
                                src='images/img-3.jpg'
                                text='Phân tích nghiệp vụ dự án chuyên nghiệp, đáp ứng chính xác và khoa học các yêu cầu của khách hàng.'
                                label='Business Analyst-BA'
                                path='/services'
                            />
                            <CardItem
                                src='images/img-4.jpg'
                                text='Thiết kế giao diện UI/UX Website và Mobile App chuyên nghiệp, đẹp mắt, bố cục hợp lý.'
                                label='UI/UX Design'
                                path='/services'
                            />
                            <CardItem
                                src='images/img-8.jpg'
                                text='Chúng tôi hỗ trợ các cá nhân, tổ chức trong lĩnh vực đào tạo phần mềm như Website, Mobile Application.'
                                label='Đào tạo'
                                path='/services'
                            />
                        </ul>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Cards;
