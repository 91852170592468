import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar(props) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);
  const handleScroll = () => {
    window.scroll(0,190)
  }
  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-brand'  onClick={closeMobileMenu}>
            <div>
              <img src='/images/logo.png' />
            </div>
          </Link>

          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className="nav-item">
              <Link
                to="/thiet_ke_app"
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                
                }}
              >
                Thiết kế App
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/thiet_ke_web"
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                }}
              >
                Thiết kế Website
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={() => { closeMobileMenu(); handleScroll() }}>
                Dịch vụ
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/services'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Liên hệ
              </Link>
            </li>
            <li className='nav-item'>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
