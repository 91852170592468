import React from "react";
import "./Footer.css";
import { Button } from "./Button";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        <p className="footer-subscription-text">
          CÔNG TY TNHH GIẢI PHÁP CÔNG NGHỆ VÀ ĐÀO TẠO AMAZINPRO{" "}
        </p>{" "}
        <p className="footer-subscription-text">
          Tầng 11 Toà nhà Việt Á, Số 09 phố Duy Tân, Phường Dịch Vọng Hậu, Quận
          Cầu Giấy, Thành phố Hà Nội
        </p>{" "}
        <p className="footer-subscription-text">
          Mã số thuế: 0109667426
        </p>{" "}
        <div>
          <img src="/images/logo.png" />
        </div>{" "}
        <small className="website-rights"> @Copyrights by AmazinPro </small>{" "}
      </section>{" "}
    </div>
  );
}

export default Footer;
