import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom";
import ContactRow from "../pages/ContactRow";
import PhoneNumberRow from "../pages/PhoneNumberRow";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader1 = {
    title: "Thiết kế App là gì ?",
    paragraph:
      "Thiết kế app là tổng hợp toàn bộ các công việc của các lập trình viên sử dụng ngôn ngữ lập trình riêng để viết ra những ứng dụng chạy trên hệ điều hành của các thiết bị di động như Android, IOS..., Ưu điểm tuyệt vời nhất của App hay còn gọi là ứng dụng di động,là người dùng có thể sử dụng mọi lúc mọi nơi chỉ cần một điện thoaị thông minh có kết nối internet, khả năng xử lý và tiện lợi luôn ở mức cao nhất.",
  };
  const sectionHeader2 = {
    title: "Lợi ích của việc thiết kế App",
    paragraph:
    "Trong thời đại kinh doanh số 4.0, App (hay gọi là ứng dụng di động) được coi như là một phần không thể thiếu trong hệ thống của một doanh nghiệp. Việc thiết kế app sẽ giúp bạn tăng độ nhận diện thương hiệu trên mạng internet, dễ dàng tiếp cận nhanh chóng và tương tác khách hàng khắp mọi miền đât nước và trên toàn thế giới"
  };


 

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader1} className="center-content" />
          <SectionHeader data={sectionHeader2} className="center-content" />
          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-01.svg")}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Tiếp cận 95% người mua hàng trên App</h4>
                  <p className="m-0 text-sm">
                    App giúp cho người dùng tiếp cận thông tin, nhanh chóng. Mặc dù xu hướng mua hàng trên sàn TMDT, mạng xã hội ngày càng cao nhưng khách hàng vẫn tin tưởng sự lựa chọn App là nơi tham khảo trước khi mua hàng
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-02.svg")}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Tăng hiệu quả của hoạt động quảng cáo</h4>
                  <p className="m-0 text-sm">
                   Thiết kế App chuyên nghiệp sẽ làm tăng hiệu quả bán hàng và chuyển dổi quảng cáo, đem lại doanh thu to lớn cho cửa hàng, doanh nghiệp và quan trọng là sự tin cậy của khác hàng
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-03.svg")}
                      alt="Features tile icon 03"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Xây dựng được thương hiệu uy tín trên toàn cầu</h4>
                  <p className="m-0 text-sm">
                    Thiết kế App với giao diện chuyên nghiệp đẹp mắt, hỗ trợ mua sắm online tiện lợi sẽ khiến cho khách hàng yêu quý,tin tưởng hơn về sản phẩm và thương hiệu của bạn.
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-04.svg")}
                      alt="Features tile icon 04"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Chăm sóc khách hàng tiện lợi</h4>
                  <p className="m-0 text-sm">
                    Khách hàng có thể tương tác, trao đổi và đóng góp với doanh nghiệp thông qua App. Mà nhờ App doanh nghiệp có thể truyền tải nội dung đến quý khách hàng một cách dễ dàng.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-05.svg")}
                      alt="Features tile icon 05"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Giữ chân khách hàng trung thành</h4>
                  <p className="m-0 text-sm">
                    Thiết kế App hướng đến mục tiêu mang đến trải nghiệm tốt nhất cho người dùng, giữ chân người dùng bằng sự tiện ích và đa năng với giao diện ấn tượng dễ dàng toạ được niềm tin trong lòng khách hàng
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require("./../../assets/images/feature-tile-icon-06.svg")}
                      alt="Features tile icon 06"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">Xây dựng tệp khách hàng tuyệt vời</h4>
                  <p className="m-0 text-sm">
                    Dựa theo thông tin liên hệ của quý khách hàng, chúng ta có thể bổ sung làm tệp khách hàng cho các chiến lượng marketing nhằm gia tăng doanh số bán hàng
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
