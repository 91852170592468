import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";
import ContactRow from "../pages/ContactRow";
import PhoneNumberRow from "../pages/PhoneNumberRow";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  };

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-48 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Công ty Thiết kế App{" "}
              <span className="text-color-primary">AmazinPro</span>
            </h1>
            <div>
              <p className="main-p">
                <p>
                  Công ty thiết kết app AmazinPro chuyên làm app giao diện đẹp,
                  nhiều tính năng, dễ sử dụng, dễ tiếp cận khách hàng tiềm năng,
                  nhằm nâng cao chất lượng dịch vụ và tăng hiệu quả kinh tế, chi
                  phí thiết kế App hợp lý.{" "}
                </p>
                <br />
                Thiết kế app bán hàng, thiết kế app giáo dục, thiết kế app trong
                ngành spa, thiết kế app trong mọi lĩnh vực, có bảo hành và theo
                yêu cầu. Tư vấn báo giá chi tiết.
              </p>
            </div>
          </div>
          <div mt-32>
            <table border="1">
              <tr>
                <td>
                  <font color="e6e600">★ </font>
                  <font color="008000">
                    Luôn có đầy đủ đội ngũ chuyên nghiệp sáng tạo
                  </font>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <font color="e6e600">★ </font>
                  <font color="008000">
                    Là một doanh nghiệp có giấy phép kinh doanh trong dịch vụ
                    thiết kế App
                  </font>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  <font color="e6e600">★ </font>
                  <font color="008000">
                    {" "}
                    Công ty có nhiều năm kinh nghiệm làm App: Nhân viên trong
                    công ty có nhiều năm trong ngành lập trình App
                  </font>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <font color="e6e600">★ </font>
                  <font color="008000">
                    Chi phí hợp lý, thời gian phát triển nhanh, luôn sử dụng
                    công nghệ mới nhất, hiện đại nhất đảm bảo App chuyên nghiệp
                  </font>
                  <br />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <font color="e6e600">★ </font>
                  <font color="008000">
                    Luôn sát sao với sản phẩm từ quá trình phát triển đến quá
                    trình vận hành với chế độ bảo hành bảo trì hợp lý, luôn là
                    một đối tác kỹ thuật tin cậy của quý khách hàng
                  </font>
                  <br />
                </td>
              </tr>
            </table>
            <div class="row">
              <ContactRow />
              <span>&nbsp;&nbsp;</span>
              <PhoneNumberRow />
            </div>
          </div>

          <div
            className="hero-figure reveal-from-bottom illustration-element-01"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            <Image
              src={require("./../../assets/images/thiet_ke_app.png")}
              alt="Hero"
              width={896}
              height={504}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
