import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import ReactPlayer from "react-player";
import { useMediaQuery } from "react-responsive";

function HeroSection() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1224px)",
      });
      const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
      const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
      const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
      const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
      if (isDesktopOrLaptop || isBigScreen)
    return (
        <div className='hero-container'>
            <ReactPlayer url={'https://laravel-vr.s3.ap-southeast-1.amazonaws.com/video/video-1.mp4'} width="100%" height="100%" playing={true} loop={true} muted={true} ></ReactPlayer>
            <div id='description_big'>
                <h1>WEBSITE & MOBILE APP SERVICES</h1>
                <p>Hãy nói cho chúng tôi biết yêu cầu của bạn !</p>
            </div>
            {
                /*
                <div className="hero-btns">
                <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
                >
                    BẮT ĐẦU
                </Button>
                <Button
                className='btns'
                buttonStyle='btn--primary'
                buttonSize='btn--large'
                >
                    XEM NHIỀU HƠN <i className='far fa-play-circle' />
                </Button>
            </div>
                 */
            }

        </div>
    )
    return (
        <div className='hero-container'>
            <ReactPlayer url={'https://laravel-vr.s3.ap-southeast-1.amazonaws.com/video/video-1.mp4'} width="100%" height="100%" playing={true} loop={true} muted={true} ></ReactPlayer>
            <div id='description_small'>
                <h1>WEBSITE & MOBILE APP SERVICES</h1>
                <p>Hãy nói cho chúng tôi biết yêu cầu của bạn !</p>
            </div>
            {
                /*
                <div className="hero-btns">
                <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
                >
                    BẮT ĐẦU
                </Button>
                <Button
                className='btns'
                buttonStyle='btn--primary'
                buttonSize='btn--large'
                >
                    XEM NHIỀU HƠN <i className='far fa-play-circle' />
                </Button>
            </div>
                 */
            }

        </div>
    )
}

export default HeroSection;