import React from "react";
import "../../App.css";
import HeroSection from "../HeroSection";
import Cards from "../Cards";
import Footer from "../Footer";
import CardItem from "../CardItem";
import ContactRow from "./ContactRow";
import PhoneNumberRow from "./PhoneNumberRow";
import Image from "../elements/Image";

function Services() {
  return (
    <>
      <div className="cards">
        <div className="cards__container">
          <div className="cards__wrapper">
            <div
              className="hero-figure reveal-from-bottom illustration-element-01"
              data-reveal-value="20px"
              data-reveal-delay="800"
            >
              <Image
                src={require("./../../assets/images/amazinpro.png")}
                alt="Hero"
                className="has-shadow"
                width={896}
                height={504}
              />
              <div class="row">
              
              <ContactRow />
              <span>&nbsp;&nbsp;</span>
              <PhoneNumberRow />
            </div>
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Services;
