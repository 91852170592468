import React from "react";
import CardItem from "../CardItem";
import { useMediaQuery } from "react-responsive";
import ContactRow from "./ContactRow";
import Contact from "./Contact";
import PhoneNumber from "./PhoneNumber";
import PhoneNumberRow from "./PhoneNumberRow";

function YouProPolicy() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  if (isDesktopOrLaptop || isBigScreen)
    return (
      <>
        <div className="cards-about">
          <div class="container">
            <div class="row">
              <div className="cards-image">
                <img src="../images/youpro.png" className="img-fluid-row" />
              </div>

              <div>
                <h3>
                  <strong>Privacy Policy</strong>
                </h3>
                <br />
                <p>
                  The following Data Protection Policy and Privacy Policy (the
                  “Privacy Policy”) is valid for downloading and use all of
                  application from YouPro. The person downloading and/or using
                  the App is in the following referred to referred to as the
                  “User”.
                </p>
                <br />
                <h4>
                  First of all,YouPro don't collect any information of user.
                </h4>
                <br />
                <p>
                  YouPro creates a system of applications that support users
                  worldwide in language translation and beauty applications. In
                  order to maintain funds in the product may have advertising
                  and purchase packages, looking forward to your understanding
                  and support. In Application, We don't collect any information
                  of user.
                </p>
                <br />
                <p>
                  <strong>Safe and Convenient</strong>
                </p>
                <br />
                <p>
                  We, YouPro is very useful and safety. it is important that you
                  as a User of our App feel secure in using the App, and it is
                  important to us to safeguard your personal information and
                  integrity. Here is a brief summary of how we Store, protect,
                  process and use personal data in accordance with the General
                  Data Protection Regulation (GDPR).
                </p>
                <p>
                  By downloading the App you agree to our Privacy Policy and our
                  use of your personal data.
                </p>
                <br />
                <h4>
                  <strong>Advertisement & Purchasing</strong>
                </h4>

                <br />
                <p>
                  Purchases within the App are done through App Store or Google
                  Play. Upon purchase, YouPro receives a transaction
                  identification number from App Store or Google Play, in order
                  to, if needed, verify the status of the purchase order from a
                  subscriber at App Store or Google Play. YouPro have no
                  possibility to connect or link this transaction id to the data
                  collected from a single User, and does not.
                </p>
                <br />

                <br />

                <p>
                  <strong>
                    This Privacy Policy have been updated and published by
                    YouPro 27.05.2022.
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <div className="cards-about">
        <div class="container">
          <div class="col">
            <div class="col-sm-6">
              <img src="../images/youpro.png" className="img-fluid-col" />
            </div>

            <div>
              <h3>
                <strong>Privacy Policy</strong>
              </h3>
              <br />
              <p>
                The following Data Protection Policy and Privacy Policy (the
                “Privacy Policy”) is valid for downloading and use all of
                application from YouPro. The person downloading and/or using the
                App is in the following referred to referred to as the “User”.
              </p>
              <br />
              <h4>
                First of all,YouPro don't collect any information of user.
              </h4>
              <br />
              <p>
                YouPro creates a system of applications that support users
                worldwide in language translation and beauty applications. In
                order to maintain funds in the product may have advertising and
                purchase packages, looking forward to your understanding and
                support. In Application, We don't collect any information of
                user.
              </p>
              <br />
              <p>
                <strong>Safe and Convenient</strong>
              </p>
              <br />
              <p>
                We, YouPro is very useful and safety. it is important that you
                as a User of our App feel secure in using the App, and it is
                important to us to safeguard your personal information and
                integrity. Here is a brief summary of how we Store, protect,
                process and use personal data in accordance with the General
                Data Protection Regulation (GDPR).
              </p>
              <p>
                By downloading the App you agree to our Privacy Policy and our
                use of your personal data.
              </p>
              <br />
              <h4>
                <strong>Advertisement & Purchasing</strong>
              </h4>

              <br />
              <p>
                Purchases within the App are done through App Store or Google
                Play. Upon purchase, YouPro receives a transaction
                identification number from App Store or Google Play, in order
                to, if needed, verify the status of the purchase order from a
                subscriber at App Store or Google Play. YouPro have no
                possibility to connect or link this transaction id to the data
                collected from a single User, and does not.
              </p>
              <br />

              <br />

              <p>
                <strong>
                  This Privacy Policy have been updated and published by YouPro
                  27.05.2022.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default YouProPolicy;
