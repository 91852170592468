import * as React from "react";
import ReactDOM from "react-dom";
import ButtonNice from "react-button-nice";
import styled from "styled-components";

const btnDefaultStyle = {
  padding: "12px 20px",
  cursor: "pointer",
  fontSize: 20,
  color: "white",
  border: "none",
  borderRadius: "40px",
  outline: "none",
};

const marginStyle = {
  display: "block",
  marginBottom: 10,
  marginTop: 25,
};

const secondStyle = {
  ...btnDefaultStyle,
  borderRadius: "4px"
};

function ContactRow()  {
  return(
      <div className="cards_contact">
        <ButtonNice alpha={0.3} style={marginStyle}>
          <button
            onClick={(e) => {
              e.preventDefault();
              window.open(`https://zalo.me/0349582808`, '_blank')
              }}
            style={{
              ...btnDefaultStyle,
              backgroundColor: "#007FFF"
            }}
          >
            Zalo
          </button>
        </ButtonNice>

       
      </div>
    );
  }


export default ContactRow;

const Button = styled.button`
  background-color: #0060ff;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  color: white;
  font-size: 16px;
  outline: none;
`;

const ButtonWhite = styled(Button)`
  border: 1px #0060ff solid;
  background-color: white;
  color: #0060ff;
`;

const ButtonA = styled.a`
  background-color: #0060ff;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  color: white;
  font-size: 16px;
  outline: none;
`;

const rootElement = document.getElementById("root");
ReactDOM.render(<ContactRow />, rootElement);