import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import Services from "./components/pages/Services";
import AntKaraPolicy from "./components/pages/AntKaraPolicy";
import YouProPolicy from "./components/pages/YouProPolicy";
import SignUp from "./components/pages/SignUp";
import Products from "./components/pages/Products";
import ThietKeApp from "./components/pages/ThietKeApp";
import ThietKeWeb from "./components/pages/ThietKeWeb";
import "./assets/scss/style.scss";
import TermsOfUseYouPro from "./components/pages/TermsOfUseYouPro";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/services" component={Services} />
          <Route exact path="/thiet_ke_app" component={ThietKeApp} />
          <Route exact path="/thiet_ke_web" component={ThietKeWeb} />
          <Route exact path="/antkara_policy" component={AntKaraPolicy} />
          <Route exact path="/youpro_policy" component={YouProPolicy} />
          <Route exact path="/youpro_terms_of_use" component={TermsOfUseYouPro} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/sign-up" component={SignUp} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
