import React from "react";
import CardItem from "../CardItem";
import { useMediaQuery } from "react-responsive";
import ContactRow from "./ContactRow";
import Contact from "./Contact";
import PhoneNumber from "./PhoneNumber";
import PhoneNumberRow from "./PhoneNumberRow";

function AntkaraPolicy() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  if (isDesktopOrLaptop || isBigScreen)
    return (
      <>
        <div className="cards-about">
          <div class="container">
            <div class="row">
              <div className="cards-image">
                <img src="../images/antkara.png" className="img-fluid-row" />
              </div>
              <div>
                <h2 className="main-title">CHÍNH SÁCH - PRIVACY POLICY</h2>
                <p className="main-p">
                  <p>
                    <h4 className="main-title">
                      {" "}
                      AntKara - Miễn phí cho tất cả{" "}
                    </h4>
                    <br />
                    <br />
                    <h4 className="main-title">
                     * GIÁ TRỊ VÀ LỢI ÍCH CỦA ANTKARA{" "}
                    </h4>
                    <br />
                    Cảm ơn bạn đã sử dụng và quan tâm đến hệ thống phần mềm AntKara,
                    <br />
                    <br />
                    AntKara là một hệ thống phần mềm và ứng dụng nhằm hỗ trợ và
                    nâng cao kiến thức, đem lại giá trị cho người sử dụng cho người sử dụng.
                    <br />
                    <br />
                    Để có kinh phí duy trì và phát triển, chúng tôi đã thêm các
                    dịch vụ, mong nhận được sự ủng hộ và thấu hiểu của bạn:
                    <br /><br />
                    + Đặt quảng cáo trong ứng dụng <br /> <br />
                    + Bán các gói dịch vụ, các gói dịch vụ này không tự động gia hạn nên người dùng không cần lo lắng bị trừ tiền khi không còn nhu cầu dùng khi hết hạn gói dịch vụ.
                    <br />
                    <br />
                    <br />
                    <h4 className="main-title">
                      1. THÔNG TIN CHÚNG TÔI THU THẬP{" "}
                    </h4>
                    <br />
                    Thông tin bạn gửi qua Dịch vụ: AntKara thu thập thông tin cá
                    nhân của bạn khi bạn đăng ký quyền truy cập vào Dịch vụ (ví
                    dụ: địa chỉ email, mật khẩu, tên người dùng), cập nhật hồ sơ
                    của bạn, sử dụng một số Dịch vụ nhất định, liên hệ với chúng
                    tôi khi có thắc mắc hoặc trả lời một trong các khảo sát của
                    chúng tôi.
                    <br />
                    <br />
                    Công nghệ theo dõi: Khi bạn tương tác với AntKara thông qua
                    Dịch vụ, chúng tôi nhận và lưu trữ một số dữ liệu không nhận
                    dạng cá nhân.
                    <br />
                    <br />
                    Những thông tin như vậy, được thu thập một cách thụ động
                    bằng nhiều công nghệ khác nhau, hiện không thể được sử dụng
                    để xác định cụ thể bạn.
                    <br />
                    <br />
                    AntKara có thể tự lưu trữ thông tin đó hoặc thông tin đó có
                    thể được đưa vào cơ sở dữ liệu do các chi nhánh, đại lý hoặc
                    nhà cung cấp dịch vụ của AntKara sở hữu và duy trì.
                    <br />
                    <br />
                    Ngoài ra, với nỗ lực không ngừng để hiểu rõ hơn và phục vụ
                    người dùng Dịch vụ, AntKara thường tiến hành nghiên cứu về
                    nhân khẩu học, sở thích và hành vi của khách hàng dựa trên
                    Dữ liệu Cá nhân và các thông tin khác được cung cấp cho
                    chúng tôi.
                    <br />
                    <br />
                    Nghiên cứu này có thể được tổng hợp và phân tích, AntKara có
                    thể tiết lộ số liệu thống kê người dùng tổng hợp để mô tả
                    Dịch vụ của chúng tôi cho các đối tác kinh doanh hiện tại và
                    tiềm năng, và cho các bên thứ ba khác vì các mục đích hợp
                    pháp khác.
                    <br />
                    <br />
                    <h4 className="main-title">
                      2. SỬ DỤNG THÔNG TIN CÁ NHÂN{" "}
                    </h4>
                    Chúng tôi sử dụng thông tin cá nhân của bạn cho các mục đích
                    có thể bao gồm:
                    <br />
                    <br />
                    + Cung cấp cho khách hàng của chúng tôi một dịch vụ được cá
                    nhân hóa.
                    <br />
                    <br />
                    + Xử lý đơn đặt hàng, đăng ký và yêu cầu.
                    <br />
                    <br />
                    + Thực hiện khảo sát nghiên cứu thị trường
                    <br />
                    <br />
                    + Đo lường sự quan tâm đến và cải thiện trang web, sản phẩm
                    và dịch vụ của chúng tôi.
                    <br />
                    <br />
                    + Cung cấp cho bạn thông tin về sản phẩm và dịch vụ mà chúng
                    tôi cung cấp.
                    <br />
                    <br />
                    + Giải quyết tranh chấp, thu phí và khắc phục sự cố.
                    <br />
                    <br />
                    <h4 className="main-title">
                      3. CÔNG BỐ VÀ THỰC TIỄN QUAN TRỌNG{" "}
                    </h4>
                    <br />
                    AntKara không kinh doanh việc bán thông tin của bạn.
                    <br />
                    <br />
                    Chúng tôi coi thông tin này là một phần quan trọng trong mối
                    quan hệ của chúng tôi với bạn. Tuy nhiên, có một số trường
                    hợp nhất định mà chúng tôi có thể chia sẻ Dữ liệu Cá nhân
                    của bạn với một số bên thứ ba nhất định mà không cần thông
                    báo thêm cho bạn, như được nêu dưới đây:
                    <br />
                    <br />
                    Chuyển giao doanh nghiệp: Khi chúng tôi phát triển công việc
                    kinh doanh của mình, chúng tôi có thể bán hoặc mua doanh
                    nghiệp hoặc tài sản. Trong trường hợp bán công ty, sáp nhập,
                    tổ chức lại, giải thể hoặc sự kiện tương tự, Dữ liệu Cá nhân
                    có thể là một phần của tài sản được chuyển giao.
                    <br />
                    <br />
                    Các Công ty Liên quan: Chúng tôi cũng có thể chia sẻ Dữ liệu
                    Cá nhân của bạn với các Công ty Liên quan của chúng tôi cho
                    các mục đích phù hợp với Chính sách Bảo mật này.
                    <br />
                    <br />
                    Đại lý, nhà tư vấn và các bên thứ ba có liên quan:AntKara,
                    giống như nhiều doanh nghiệp, đôi khi thuê các công ty khác
                    để thực hiện một số chức năng liên quan đến kinh doanh. Ví
                    dụ về các chức năng như bao gồm thông tin gửi thư, duy trì
                    cơ sở dữ liệu và xử lý các khoản thanh toán. Khi chúng tôi
                    thuê một công ty khác để thực hiện một chức năng có tính
                    chất này, chúng tôi chỉ cung cấp cho họ thông tin mà họ cần
                    để thực hiện chức năng cụ thể của mình.
                    <br />
                    <br />
                    Yêu cầu pháp lý: AntKara có thể tiết lộ Dữ liệu Cá nhân của
                    bạn nếu luật pháp yêu cầu hoặc với thiện chí tin rằng hành
                    động đó là cần thiết để (i) tuân thủ nghĩa vụ pháp lý, (ii)
                    bảo vệ và bảo vệ các quyền hoặc tài sản của AntKara, (iii)
                    hành động trong những trường hợp khẩn cấp để bảo vệ quyền an
                    toàn cá nhân của người dùng Dịch vụ hoặc công chúng, hoặc
                    (iv) bảo vệ khỏi trách nhiệm pháp lý.
                    <br />
                    <br />
                    <h4 className="main-title">4. LỰA CHỌN VÀ CHỌN</h4>
                    <br />
                    Bạn có thể chọn không sử dụng thông tin của mình như đã nêu
                    ở trên bằng cách gửi email cho chúng tôi theo địa chỉ
                    amzinproservice@gmail.com hoặc bằng cách đặt tùy chọn trực
                    tuyến của riêng bạn để nhận email quảng cáo và tiếp thị của
                    AntKara.
                    <br />
                    <br />
                    Ví dụ: nếu bạn không muốn chúng tôi sử dụng địa chỉ email
                    của bạn hoặc thông tin liên hệ khác để cung cấp cho bạn các
                    ưu đãi hoặc không còn muốn nhận bản tin email của chúng tôi,
                    bạn có thể chọn không nhận bằng cách làm theo các hướng dẫn
                    có trong mỗi bản tin hoặc bằng cách gửi email cho chúng tôi
                    theo địa chỉ amazinproservice@gmail.com .
                    <br />
                    <br />
                    <h4 className="main-title">
                      5. TRUY CẬP THÔNG TIN CỦA BẠN{" "}
                    </h4>
                    <br />
                    Sau khi đăng ký Dịch vụ, bạn có thể cập nhật thông tin nhận
                    dạng cá nhân của mình bất kỳ lúc nào. Bạn cũng có thể chọn
                    “hủy đăng ký” bằng cách nhấp vào liên kết “Hủy đăng ký” để
                    xóa địa chỉ email của bạn khỏi danh sách gửi thư của chúng
                    tôi.
                    <br />
                    <br />
                    Tuy nhiên, chúng tôi không thể cập nhật hoặc xóa bất kỳ
                    thông tin nhận dạng cá nhân nào của bạn đã được chia sẻ với
                    bên thứ ba khi được cho phép trong Chính sách bảo mật này.
                    <br />
                    <br />
                    Nếu vì bất kỳ lý do gì mà bạn lo ngại rằng thông tin cá nhân
                    AntKara có trong hồ sơ không chính xác hoặc bạn muốn xóa địa
                    chỉ email của mình khỏi danh sách gửi thư hoặc thông tin
                    đăng ký / liên hệ của chúng tôi được thay đổi hoặc cập nhật,
                    vui lòng liên hệ với chúng tôi theo địa chỉ
                    amazinproservice@gmail.com .
                    <br />
                    <br />
                    Chúng tôi sẽ sẵn lòng xem xét, cập nhật hoặc xóa thông tin
                    nếu thích hợp. Tuy nhiên, chúng tôi vẫn có thể giữ lại thông
                    tin của bạn trong các tệp của mình, để giải quyết tranh
                    chấp, thực thi Điều khoản Dịch vụ của chúng tôi hoặc do các
                    yêu cầu và ràng buộc về kỹ thuật và pháp lý liên quan đến
                    tính bảo mật, tính toàn vẹn và hoạt động của Trang web và
                    Dịch vụ.
                    <br />
                    <br />
                    <h4 className="main-title">6. CÁCH ANTKARA BẢO VỆ THÔNG TIN CỦA BẠN{" "} </h4>
                    <br />
                    Chúng tôi có các quy trình bảo mật để lưu trữ và tiết lộ
                    thông tin cá nhân mà bạn đã cung cấp cho chúng tôi để ngăn
                    chặn truy cập trái phép.
                    <br />
                    <br />
                    Tuy nhiên, hãy nhớ rằng không có phương thức truyền tải nào
                    qua Internet, hoặc phương thức lưu trữ điện tử nào là hoàn
                    toàn an toàn hoặc không có lỗi.
                    <br />
                    <br />
                    Mặc dù chúng tôi cố gắng sử dụng các phương tiện được chấp
                    nhận về mặt thương mại để bảo vệ thông tin cá nhân của bạn,
                    nhưng chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của
                    thông tin đó. Do đó, bạn nên đặc biệt lưu ý trong việc quyết
                    định thông tin bạn gửi cho chúng tôi theo cách này.
                    <br />
                    <br />
                    <h4 className="main-title">
                      7. CÁCH LIÊN HỆ VỚI CHÚNG TÔI{" "}
                    </h4>
                    <br />
                    Vui lòng liên hệ với chúng tôi theo địa chỉ
                    amazinproservice@gmail.com hoặc Zalo, Facebook Group có
                    trong ứng dụng nếu bạn có bất kỳ câu hỏi nào về Chính sách
                    quyền riêng tư của AntKara hoặc các thông lệ về thông tin
                    của AntKara .
                    <br />
                    <br />
                    
                    <h4 className="main-title">8. SỬA ĐỔI CHÍNH SÁCH BẢO MẬT{" "} </h4>
                    <br />
                    Dịch vụ và hoạt động kinh doanh của chúng tôi có thể thay
                    đổi theo thời gian. Do đó, đôi khi AntKara có thể cần thiết
                    phải thực hiện các thay đổi đối với Chính sách Bảo mật này.
                    AntKara có quyền cập nhật hoặc sửa đổi Chính sách Bảo mật
                    này vào bất kỳ lúc nào và bất kỳ lúc nào mà không cần thông
                    báo trước.
                    <br />
                    <br />
                    Vui lòng xem lại chính sách này định kỳ và đặc biệt là trước
                    khi bạn cung cấp bất kỳ Dữ liệu Cá nhân nào.
                    <br />
                    <br />
                    Chính sách Bảo mật này được cập nhật lần cuối vào NGÀY CÓ
                    HIỆU LỰC được đề cập bên dưới.
                    <br />
                    <br />
                    Việc bạn tiếp tục sử dụng Dịch vụ sau bất kỳ thay đổi hoặc
                    sửa đổi nào đối với Chính sách quyền riêng tư này sẽ cho
                    thấy sự đồng ý của bạn với các điều khoản của Chính sách
                    quyền riêng tư sửa đổi đó.
                    <br />
                    <br />
                    <h4 className="main-title">
                      NGÀY CÓ HIỆU LỰC: 15/10/2021{" "}
                    </h4>
                    <br />
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  return (
    <>
      <div className="cards-about">
        <div class="container">
          <div class="col">
            <div class="col-sm-6">
              <img src="../images/antkara.png" className="img-fluid-col" />
            </div>
            <div class="main-p">
            <h2 className="main-title">CHÍNH SÁCH - PRIVACY POLICY</h2>
                <p className="main-p">
                  <p>
                    <h4 className="main-title">
                      {" "}
                      AntKara - Miễn phí cho tất cả{" "}
                    </h4>
                    <br />
                    <br />
                    <h4 className="main-title">
                      * GIÁ TRỊ VÀ LỢI ÍCH CỦA ANTKARA{" "}
                    </h4>
                    <br />
                    Cảm ơn bạn đã sử dụng và quan tâm đến hệ thống phần mềm AntKara,
                    <br />
                    <br />
                    AntKara là một hệ thống phần mềm và ứng dụng nhằm hỗ trợ và
                    nâng cao kiến thức, đem lại giá trị cho người sử dụng cho người sử dụng.
                    <br />
                    <br />
                    Để có kinh phí duy trì và phát triển, chúng tôi đã thêm các
                    dịch vụ, mong nhận được sự ủng hộ và thấu hiểu của bạn:
                    <br /><br />
                    + Đặt quảng cáo trong ứng dụng <br /> <br />
                    + Bán các gói dịch vụ, các gói dịch vụ này không tự động gia hạn nên người dùng không cần lo lắng bị trừ tiền khi không còn nhu cầu dùng khi hết hạn gói dịch vụ.
                    <br />
                    <br />
                    <br />
                    <h4 className="main-title">
                      1. THÔNG TIN CHÚNG TÔI THU THẬP{" "}
                    </h4>
                    <br />
                    Thông tin bạn gửi qua Dịch vụ: AntKara thu thập thông tin cá
                    nhân của bạn khi bạn đăng ký quyền truy cập vào Dịch vụ (ví
                    dụ: địa chỉ email, mật khẩu, tên người dùng), cập nhật hồ sơ
                    của bạn, sử dụng một số Dịch vụ nhất định, liên hệ với chúng
                    tôi khi có thắc mắc hoặc trả lời một trong các khảo sát của
                    chúng tôi.
                    <br />
                    <br />
                    Công nghệ theo dõi: Khi bạn tương tác với AntKara thông qua
                    Dịch vụ, chúng tôi nhận và lưu trữ một số dữ liệu không nhận
                    dạng cá nhân.
                    <br />
                    <br />
                    Những thông tin như vậy, được thu thập một cách thụ động
                    bằng nhiều công nghệ khác nhau, hiện không thể được sử dụng
                    để xác định cụ thể bạn.
                    <br />
                    <br />
                    AntKara có thể tự lưu trữ thông tin đó hoặc thông tin đó có
                    thể được đưa vào cơ sở dữ liệu do các chi nhánh, đại lý hoặc
                    nhà cung cấp dịch vụ của AntKara sở hữu và duy trì.
                    <br />
                    <br />
                    Ngoài ra, với nỗ lực không ngừng để hiểu rõ hơn và phục vụ
                    người dùng Dịch vụ, AntKara thường tiến hành nghiên cứu về
                    nhân khẩu học, sở thích và hành vi của khách hàng dựa trên
                    Dữ liệu Cá nhân và các thông tin khác được cung cấp cho
                    chúng tôi.
                    <br />
                    <br />
                    Nghiên cứu này có thể được tổng hợp và phân tích, AntKara có
                    thể tiết lộ số liệu thống kê người dùng tổng hợp để mô tả
                    Dịch vụ của chúng tôi cho các đối tác kinh doanh hiện tại và
                    tiềm năng, và cho các bên thứ ba khác vì các mục đích hợp
                    pháp khác.
                    <br />
                    <br />
                    <h4 className="main-title">
                      2. SỬ DỤNG THÔNG TIN CÁ NHÂN{" "}
                    </h4>
                    Chúng tôi sử dụng thông tin cá nhân của bạn cho các mục đích
                    có thể bao gồm:
                    <br />
                    + Cung cấp cho khách hàng của chúng tôi một dịch vụ được cá
                    nhân hóa.
                    <br />
                    <br />
                    + Xử lý đơn đặt hàng, đăng ký và yêu cầu.
                    <br />
                    <br />
                    + Thực hiện khảo sát nghiên cứu thị trường
                    <br />
                    <br />
                    + Đo lường sự quan tâm đến và cải thiện trang web, sản phẩm
                    và dịch vụ của chúng tôi.
                    <br />
                    <br />
                    + Cung cấp cho bạn thông tin về sản phẩm và dịch vụ mà chúng
                    tôi cung cấp.
                    <br />
                    <br />
                    + Giải quyết tranh chấp, thu phí và khắc phục sự cố.
                    <br />
                    <br />
                    <h4 className="main-title">
                      3. CÔNG BỐ VÀ THỰC TIỄN QUAN TRỌNG{" "}
                    </h4>
                    <br />
                    AntKara không kinh doanh việc bán thông tin của bạn.
                    <br />
                    <br />
                    Chúng tôi coi thông tin này là một phần quan trọng trong mối
                    quan hệ của chúng tôi với bạn. Tuy nhiên, có một số trường
                    hợp nhất định mà chúng tôi có thể chia sẻ Dữ liệu Cá nhân
                    của bạn với một số bên thứ ba nhất định mà không cần thông
                    báo thêm cho bạn, như được nêu dưới đây:
                    <br />
                    <br />
                    Chuyển giao doanh nghiệp: Khi chúng tôi phát triển công việc
                    kinh doanh của mình, chúng tôi có thể bán hoặc mua doanh
                    nghiệp hoặc tài sản. Trong trường hợp bán công ty, sáp nhập,
                    tổ chức lại, giải thể hoặc sự kiện tương tự, Dữ liệu Cá nhân
                    có thể là một phần của tài sản được chuyển giao.
                    <br />
                    <br />
                    Các Công ty Liên quan: Chúng tôi cũng có thể chia sẻ Dữ liệu
                    Cá nhân của bạn với các Công ty Liên quan của chúng tôi cho
                    các mục đích phù hợp với Chính sách Bảo mật này.
                    <br />
                    <br />
                    Đại lý, nhà tư vấn và các bên thứ ba có liên quan:AntKara,
                    giống như nhiều doanh nghiệp, đôi khi thuê các công ty khác
                    để thực hiện một số chức năng liên quan đến kinh doanh. Ví
                    dụ về các chức năng như bao gồm thông tin gửi thư, duy trì
                    cơ sở dữ liệu và xử lý các khoản thanh toán. Khi chúng tôi
                    thuê một công ty khác để thực hiện một chức năng có tính
                    chất này, chúng tôi chỉ cung cấp cho họ thông tin mà họ cần
                    để thực hiện chức năng cụ thể của mình.
                    <br />
                    <br />
                    Yêu cầu pháp lý: AntKara có thể tiết lộ Dữ liệu Cá nhân của
                    bạn nếu luật pháp yêu cầu hoặc với thiện chí tin rằng hành
                    động đó là cần thiết để (i) tuân thủ nghĩa vụ pháp lý, (ii)
                    bảo vệ và bảo vệ các quyền hoặc tài sản của AntKara, (iii)
                    hành động trong những trường hợp khẩn cấp để bảo vệ quyền an
                    toàn cá nhân của người dùng Dịch vụ hoặc công chúng, hoặc
                    (iv) bảo vệ khỏi trách nhiệm pháp lý.
                    <br />
                    <br />
                    <h4 className="main-title">4. LỰA CHỌN VÀ CHỌN</h4>
                    <br />
                    Bạn có thể chọn không sử dụng thông tin của mình như đã nêu
                    ở trên bằng cách gửi email cho chúng tôi theo địa chỉ
                    amzinproservice@gmail.com hoặc bằng cách đặt tùy chọn trực
                    tuyến của riêng bạn để nhận email quảng cáo và tiếp thị của
                    AntKara.
                    <br />
                    <br />
                    Ví dụ: nếu bạn không muốn chúng tôi sử dụng địa chỉ email
                    của bạn hoặc thông tin liên hệ khác để cung cấp cho bạn các
                    ưu đãi hoặc không còn muốn nhận bản tin email của chúng tôi,
                    bạn có thể chọn không nhận bằng cách làm theo các hướng dẫn
                    có trong mỗi bản tin hoặc bằng cách gửi email cho chúng tôi
                    theo địa chỉ amazinproservice@gmail.com .
                    <br />
                    <br />
                    <h4 className="main-title">
                      5. TRUY CẬP THÔNG TIN CỦA BẠN{" "}
                    </h4>
                    <br />
                    Sau khi đăng ký Dịch vụ, bạn có thể cập nhật thông tin nhận
                    dạng cá nhân của mình bất kỳ lúc nào. Bạn cũng có thể chọn
                    “hủy đăng ký” bằng cách nhấp vào liên kết “Hủy đăng ký” để
                    xóa địa chỉ email của bạn khỏi danh sách gửi thư của chúng
                    tôi.
                    <br />
                    <br />
                    Tuy nhiên, chúng tôi không thể cập nhật hoặc xóa bất kỳ
                    thông tin nhận dạng cá nhân nào của bạn đã được chia sẻ với
                    bên thứ ba khi được cho phép trong Chính sách bảo mật này.
                    <br />
                    <br />
                    Nếu vì bất kỳ lý do gì mà bạn lo ngại rằng thông tin cá nhân
                    AntKara có trong hồ sơ không chính xác hoặc bạn muốn xóa địa
                    chỉ email của mình khỏi danh sách gửi thư hoặc thông tin
                    đăng ký / liên hệ của chúng tôi được thay đổi hoặc cập nhật,
                    vui lòng liên hệ với chúng tôi theo địa chỉ
                    amazinproservice@gmail.com .
                    <br />
                    <br />
                    Chúng tôi sẽ sẵn lòng xem xét, cập nhật hoặc xóa thông tin
                    nếu thích hợp. Tuy nhiên, chúng tôi vẫn có thể giữ lại thông
                    tin của bạn trong các tệp của mình, để giải quyết tranh
                    chấp, thực thi Điều khoản Dịch vụ của chúng tôi hoặc do các
                    yêu cầu và ràng buộc về kỹ thuật và pháp lý liên quan đến
                    tính bảo mật, tính toàn vẹn và hoạt động của Trang web và
                    Dịch vụ.
                    <br />
                    <br />
                    <h4 className="main-title">6. CÁCH ANTKARA BẢO VỆ THÔNG TIN CỦA BẠN{" "} </h4>
                    <br />
                    Chúng tôi có các quy trình bảo mật để lưu trữ và tiết lộ
                    thông tin cá nhân mà bạn đã cung cấp cho chúng tôi để ngăn
                    chặn truy cập trái phép.
                    <br />
                    <br />
                    Tuy nhiên, hãy nhớ rằng không có phương thức truyền tải nào
                    qua Internet, hoặc phương thức lưu trữ điện tử nào là hoàn
                    toàn an toàn hoặc không có lỗi.
                    <br />
                    <br />
                    Mặc dù chúng tôi cố gắng sử dụng các phương tiện được chấp
                    nhận về mặt thương mại để bảo vệ thông tin cá nhân của bạn,
                    nhưng chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của
                    thông tin đó. Do đó, bạn nên đặc biệt lưu ý trong việc quyết
                    định thông tin bạn gửi cho chúng tôi theo cách này.
                    <br />
                    <br />
                    <h4 className="main-title">
                      7. CÁCH LIÊN HỆ VỚI CHÚNG TÔI{" "}
                    </h4>
                    <br />
                    Vui lòng liên hệ với chúng tôi theo địa chỉ
                    amazinproservice@gmail.com hoặc Zalo, Facebook Group có
                    trong ứng dụng nếu bạn có bất kỳ câu hỏi nào về Chính sách
                    quyền riêng tư của AntKara hoặc các thông lệ về thông tin
                    của AntKara .
                    <br />
                    <br />
                    
                    <h4 className="main-title">8. SỬA ĐỔI CHÍNH SÁCH BẢO MẬT{" "} </h4>
                    <br />
                    Dịch vụ và hoạt động kinh doanh của chúng tôi có thể thay
                    đổi theo thời gian. Do đó, đôi khi AntKara có thể cần thiết
                    phải thực hiện các thay đổi đối với Chính sách Bảo mật này.
                    AntKara có quyền cập nhật hoặc sửa đổi Chính sách Bảo mật
                    này vào bất kỳ lúc nào và bất kỳ lúc nào mà không cần thông
                    báo trước.
                    <br />
                    <br />
                    Vui lòng xem lại chính sách này định kỳ và đặc biệt là trước
                    khi bạn cung cấp bất kỳ Dữ liệu Cá nhân nào.
                    <br />
                    <br />
                    Chính sách Bảo mật này được cập nhật lần cuối vào NGÀY CÓ
                    HIỆU LỰC được đề cập bên dưới.
                    <br />
                    <br />
                    Việc bạn tiếp tục sử dụng Dịch vụ sau bất kỳ thay đổi hoặc
                    sửa đổi nào đối với Chính sách quyền riêng tư này sẽ cho
                    thấy sự đồng ý của bạn với các điều khoản của Chính sách
                    quyền riêng tư sửa đổi đó.
                    <br />
                    <br />
                    <h4 className="main-title">
                      NGÀY CÓ HIỆU LỰC: 15/10/2021{" "}
                    </h4>
                    <br />
                  </p>
                </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AntkaraPolicy;
