import React from "react";
import CardItem from "../CardItem";
import { useMediaQuery } from "react-responsive";
import ContactRow from "./ContactRow";
import Contact from "./Contact";
import PhoneNumber from "./PhoneNumber";
import PhoneNumberRow from "./PhoneNumberRow";
import { Link } from 'react-router-dom';
import Hero from '../sections/Hero';
import FeaturesTiles from '../sections/FeaturesTiles';
import FeaturesSplit from '../sections/FeaturesSplit';
import Testimonial from '../sections/Testimonial';
import Cta from '../sections/Cta';

function ThietKeApp() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split />
    </>
  );
}
export default ThietKeApp;
