import * as React from "react";
import ReactDOM from "react-dom";
import ButtonNice from "react-button-nice";
import styled from "styled-components";

const btnDefaultStyle = {
  padding: "12px 32px",
  cursor: "pointer",
  fontSize: 20,
  color: "white",
  border: "none",
  borderRadius: "40px",
  outline: "none",
};

const marginStyle = {
  display: "block",
  marginBottom: 10,
};

const secondStyle = {
  ...btnDefaultStyle,
  borderRadius: "4px",
};

function PhoneNumber() {
  return (
    <div className="cards_contact">
      <ButtonNice alpha={0.3} style={marginStyle}>
        <button
          onClick={(e) => {
            e.preventDefault();
            window.open(`tel:0349582808`, "_blank");
          }}
          style={{
            ...btnDefaultStyle,
            backgroundColor: "#F33614",
          }}
        >
          0349582808
        </button>
      </ButtonNice>
    </div>
  );
}

export default PhoneNumber;

const Button = styled.button`
  background-color: #0000ffff;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  color: white;
  font-size: 16px;
  outline: none;
`;

const ButtonWhite = styled(Button)`
  border: 1px #0000ffff solid;
  background-color: white;
  color: #0000ffff;
`;

const ButtonA = styled.a`
  background-color: #0000ffff;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px 32px;
  border: none;
  color: white;
  font-size: 16px;
  outline: none;
`;

const rootElement = document.getElementById("root");
ReactDOM.render(<PhoneNumber />, rootElement);
