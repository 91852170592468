import React from "react";
import CardItem from "../CardItem";
import "../Cards.css";

function MobileApps() {
  return (
    <>
      <div className="cards">
        <h1>Dịch vụ thiết kế và phát triển Ứng dụng di động</h1>
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
            <CardItem
                src="images/mobile_food.png"
                text="Lĩnh vực Food & Beverage, Shipping"
                label="Food Delivery"
                path="/services"
              />
              <CardItem
                src="images/mobile_lms.png"
                text="Lĩnh vực học trực tuyến"
                label="Globy LMS"
                path="/services"
              />
            </ul>
            <ul className="cards__items">
              <CardItem
                src="images/mobile_shop.png"
                text="Thiết kế và phát triển ứng dụng trong lĩnh vực thương mại"
                label="Ứng dụng bán hàng"
                path="/services"
              />
              <CardItem
                src="images/mobile_hotel.png"
                text="Thiết kế và phát triển ứng dụng trong lĩnh vực nhà nghỉ, khách sạn"
                label="Ứng dụng đặt phòng khách sạn"
                path="/services"
              />
              
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileApps;
