import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import Footer from '../Footer';
import MobileApps from "./MobileApps";
import About from "./About";
import Websites from "./Websites";
import Contact from "./Contact";

function Home(props) {
   

    return (
        <>
      <HeroSection />
      <About />
      <Websites />
      <MobileApps />
      <Cards />
      <Footer />
    </>
    )
}

export default Home;